/*
#    _______  __        ______   .______        ___       __
#   /  _____||  |      /  __  \  |   _  \      /   \     |  |
#  |  |  __  |  |     |  |  |  | |  |_)  |    /  ^  \    |  |
#  |  | |_ | |  |     |  |  |  | |   _  <    /  /_\  \   |  |
#  |  |__| | |  `----.|  `--'  | |  |_)  |  /  _____  \  |  `----.
#   \______| |_______| \______/  |______/  /__/     \__\ |_______|
#
*/

* {
  box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}
html, body {
    font-family: $site-font;
    font-size: 16px;
}

main {
    position: absolute;
    width: 100%;
    height: 100%;
    margin-top: 50px;
}

%section__header {
    text-align: center;
    // text-decoration: underline;
}

.section__content {
    width: 80%;
    margin: 0 auto;
    max-width: 1200px;
    padding-bottom: 20px;
}

.code__block {
    display: inline-block;
    background: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    line-height: 1.5;
    margin: 0 0 10px 0;

    &--half {
        width: 48%;
        margin-right: 2%;
    }
}