/*
#       ___      .______     ______    __    __  .___________.
#      /   \     |   _  \   /  __  \  |  |  |  | |           |
#     /  ^  \    |  |_)  | |  |  |  | |  |  |  | `---|  |----`
#    /  /_\  \   |   _  <  |  |  |  | |  |  |  |     |  |
#   /  _____  \  |  |_)  | |  `--'  | |  `--'  |     |  |
#  /__/     \__\ |______/   \______/   \______/      |__|
#
*/

.about {
    background: $light-gray url(../../images/about.png) bottom no-repeat;
    background-size: 100%;
    padding: 50px 20px 20px;
    color: black;
    f

    .btn {
        width: 100%;
        display: inline-block;
    }

    @media (min-width: 56.25em) { // width: 900px
        & {
            background-position: -17% 100%;
            background-size: 50%;
        }
    }
    @media (min-width: 75em) { // width: 1200px
        & {
            background-position: -120px 100%;
            background-size: 700px;
            padding: 80px;
        }
    }

    &__text-cntr {
        margin-bottom: 70%;

        @media (min-width: 56.25em) { // width: 900px
            & {
                width: 60%;
                margin-bottom: 0;
            }
        }
        @media (min-width: 75em) { // width: 1200px
            & {
                max-width: 600px;
                margin: 0 auto;
                background: rgba(0,0,0,0.2);
                padding: 0 30px 30px;
                border-radius: 4px;
                border: 1px solid rgba(0,0,0,0.1);
            }
        }
    }
    &__title {
        text-align: center;
    }
    &__text {
        margin-bottom: 20px;
    }

    &--more {
        background: none;
        width: 90%;
        max-width: 1050px;
        margin: 0 auto;
        color: #666;
        padding: 30px 0 0;

        p {
            margin-bottom: 20px;
        }

        a {
            @include stylized-links;
        }
    }
}