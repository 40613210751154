// Fonts
$site-font: 'Open Sans', Helvetica, sans-serif;
$header-font: Futura, Helvetica, sans-serif;

// Colors:
$blue: #3fb0ac;
$dark-blue: #007580;
$yellow: #ffc93c;
$red: #f05945;
$light-gray: #f7f3e9;
$hookem-orange: #C15700;