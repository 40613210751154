/*
#  .______     ______   .______      .___________. _______   ______    __       __    ______
#  |   _  \   /  __  \  |   _  \     |           ||   ____| /  __  \  |  |     |  |  /  __  \
#  |  |_)  | |  |  |  | |  |_)  |    `---|  |----`|  |__   |  |  |  | |  |     |  | |  |  |  |
#  |   ___/  |  |  |  | |      /         |  |     |   __|  |  |  |  | |  |     |  | |  |  |  |
#  |  |      |  `--'  | |  |\  \----.    |  |     |  |     |  `--'  | |  `----.|  | |  `--'  |
#  | _|       \______/  | _| `._____|    |__|     |__|      \______/  |_______||__|  \______/
#
*/
.portfolio {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    overflow: hidden;
    margin: 0 auto;
    max-width: 1800px;

    .box {
        position: relative;
        background-color: $dark-blue;
        align-items: center;
        justify-content: center;
        margin: 20px;
        border: 1px solid #eee;
        transition: all 0.3s ease-in-out;
        padding-bottom: 50px;

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        .projectAbout {
            padding: 20px 20px 20px;
            color: white;

            p {
                text-align: left;
            }

            button {
                position: absolute;
                bottom: 20px;
            }
        }

        &:hover {
            cursor: pointer;
            // transform: rotate(random(1)+deg);
            transform: scale(1.03);
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
        }

        & > img {
            width: 100%;
            max-height: 200px;
            background: white;
        }
    }

    &__item {
        width: 100%;
        position: relative;
        overflow: hidden;

        &:hover, &:focus, &:active {
            @media (min-width: 56.25em) { // width: 900px
                .portfolio__description {
                    display: block;
                    opacity: 1;
                    z-index: 1000;
                    bottom: 0;
                }
                .portfolio__overlay {
                    bottom: 0;
                }
            }
        }

        a:not(.btn) {
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 10;
        }

        @media (min-width: 56.25em) { // width: 900px
            & {
                float: left;
                width: 50%;
            }
        }
        @media (min-width: 75em) { // width: 1200px
            & {
                width: 25%;
            }
        }
    }

    &__overlay {
        min-height: 40px;
        height: auto;
        width: 100%;
        background: rgba(51,51,51,0.7);
        z-index: 9999;
        position: relative;
        left: 0;
        padding: 10px;
        text-align: center;
        display: block;
        color: white;
        box-shadow: inset 0 -2px 2px #666666;

        @media (min-width: 56.25em) { // width: 900px
            & {
                background: rgba(0,0,0,0.5);
                z-index: 1000;
                position: absolute;
                bottom: -25%;
                transition: bottom 0.8s ease-in-out;
                box-shadow: none;
            }
        }

        &--text {
            font-size: 0.9rem;
            font-weight: 300;
            z-index: 1;
            margin-bottom: 0;
        }
    }

    &__description {
        position: absolute;
        height: 100%;
        width: 100%;
        color: #fff;
        background: rgba(0,0,0,0.3);
        opacity: 0;
        text-align: center;

        @media (min-width: 56.25em) { // width: 900px
            & {
                transition: all 0.6s ease-in-out;
            }
        }

        &:hover, &:focus, &:active {
            display: block;
            opacity: 1;
            z-index: 1000;
            bottom: 0;
        }

        &--btn {
            @include vertical-align;

        }
    }

    &__image {
        width: 100%;
        height: 100%;
        display: block;
    }
}
