/*
#   _______ ___   ___ .______    _______ .______       __   _______ .__   __.   ______  _______
#  |   ____|\  \ /  / |   _  \  |   ____||   _  \     |  | |   ____||  \ |  |  /      ||   ____|
#  |  |__    \  V  /  |  |_)  | |  |__   |  |_)  |    |  | |  |__   |   \|  | |  ,----'|  |__
#  |   __|    >   <   |   ___/  |   __|  |      /     |  | |   __|  |  . `  | |  |     |   __|
#  |  |____  /  .  \  |  |      |  |____ |  |\  \----.|  | |  |____ |  |\   | |  `----.|  |____
#  |_______|/__/ \__\ | _|      |_______|| _| `._____||__| |_______||__| \__|  \______||_______|
#
*/

.experience {
    padding: 50px 20px 0;
    overflow: hidden;

    &__header {
        @extend %section__header;
    }

    &__date {
        color: #aaa;

        @media (min-width: 56.25em) { // width: 900px
            & {
                text-align: center;
            }
        }
    }

    &__timeline {
        position: relative;
        padding-left: 20px;

        @media (min-width: 56.25em) { // width: 900px
            & {
                max-width: 80%;
                margin: 0 auto;
            }
        }
    }

    &__job {
        @media (min-width: 56.25em) { // width: 900px
            & {
                width: 50%;


                &:nth-child(odd) {
                    margin: 0 0 0 50%;
                }
            }
        }
    }

    @media (min-width: 56.25em) { // width: 900px
        .experience__job:nth-child(even) .experience__label {
            margin-right: 3rem;
            margin-left: 0;

            &:before {
               left: inherit;
               right: -1.4em;
            }
            &:after {
               left: initial;
               right: -51px;
            }
        }
    }

    &__label {
        border: 4px solid $dark-blue;
        margin: 20px 0 20px 30px;
        padding: 20px 20px 20px 30px;
        position: relative;
        color: white;
        background: $dark-blue;

        &:before {
            content: '';
            position: absolute;
            top: 2.9em; left: -1.37em;
            padding: 1.05em;
            background: inherit;
            border: inherit;
            border-right: 0;
            border-bottom: 0;
            transform: rotate(-45deg);
        }
        &:after {
            content: '';
            width: 10px;
            height: 10px;
            background: inherit;
            border: 1px solid white;
            border-radius: 50px;
            position: absolute;
            left: -58px;
            top: 59px;
            z-index: 99999;

            @media (min-width: 56.25em) { // width: 900px
                & {
                    left: -48px;
                }
            }
        }
    }

    &__details {
        width: 100%;

        @media (min-width: 75em) { // width: 900px
            & {
                float: left;
                width: 40%;
            }
        }

        &--title {
            font-weight: 200;
            font-size: 1.1rem;
            margin-bottom: 0;
        }

        &--company {
            font-weight: 400;
            font-size: 1.3rem;
            margin-bottom: 0;
        }
    }

    &__description {
        width: 100%;
        display: inline-block;
        padding-top: 4%;
        border-top: 1px solid white;

        @media (min-width: 75em) { // width: 900px
            & {
                width: 60%;
                margin: 0 0 0 0%;
                padding-top: 0;
                padding-left: 4%;
                border: none;
                border-left: 1px solid white;
            }
        }
    }

    &__dateline {
        width: 5px;
        height: 100%;
        display: inline-block;
        position: absolute;
        background: linear-gradient(180deg, $yellow 50%, lighten($yellow, 20%) 100%);
        top: 0;
        left: 0;
        z-index: 0;

        @media (min-width: 56.25em) { // width: 900px
            & {
                left: 50%;
            }
        }
    }
}