/*
#  .__   __.      ___      ____    ____
#  |  \ |  |     /   \     \   \  /   /
#  |   \|  |    /  ^  \     \   \/   /
#  |  . `  |   /  /_\  \     \      /
#  |  |\   |  /  _____  \     \    /
#  |__| \__| /__/     \__\     \__/
#
*/

.menu {
    position: fixed;
    width: 100%;
    height: 50px;
    background: #262626;
    box-shadow: 0 0 1px rgba(0,0,0,0.5);
    z-index: 2;
    padding: 15px 20px;
    text-align: center;
    filter: opacity(90%);

    &__item {
        display: inline-block;
        padding: 0 10px;
        // border-right: 1px solid white;

        &:first-child {
            border-left: 0;
        }
        &:last-child {
            border-right: none;
            padding-right: 0;
        }
    }

    &__link {
        color: white;
        font-weight: 300;
        border-bottom: none;
        background: none;
        border: none;
        font-size: 1em;
        font-family: "Open Sans", Helvetica, sans-serif;

        &:hover {
            background: rgba(0,0,0,0.5);
            border-radius: 6px;
            box-shadow: inset 0 0 0 1px rgba(0,0,0,1);
            cursor: pointer;
        }

        &--selected {
            background: rgba(0,0,0,0.5);
            border-radius: 6px;
            box-shadow: inset 0 0 0 1px rgba(0,0,0,1);
            font-weight: 700;
        }
    }
}