/*
#       _______. __  ___  __   __       __           _______.
#      /       ||  |/  / |  | |  |     |  |         /       |
#     |   (----`|  '  /  |  | |  |     |  |        |   (----`
#      \   \    |    <   |  | |  |     |  |         \   \
#  .----)   |   |  .  \  |  | |  `----.|  `----..----)   |
#  |_______/    |__|\__\ |__| |_______||_______||_______/
#
*/

.skills {
    text-align: center;
    background: $dark-blue;
    padding: 0 0 40px;
    color: white;

    &__header {
        color: white;
    }

    &__header {
        @extend %section__header;
    }

    > div {
        width: 80%;
        margin: 0 auto;
        text-align: left;

        p {
            margin-bottom: 20px;
        }
    }
    &__level {
        display: block;
        width: 100%;
        // border: 1px solid white;
        margin: 0 0 2px 0;
        height: 40px;
        position: relative;
        padding: 5px 0 0 10px;
    }
    &__label {
        position: relative;
        z-index: 1;
        color: $dark-blue;
        font-weight: 900;
    }
    &__bar {
        height: 100%;
        background: $yellow;
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
        transition: all 1s ease-in;

        &--html {
            width: 100%;
            transition-delay: random()+s;
        }
        &--css {
            width: 100%;
            transition-delay: random()+s;
        }
        &--js {
            width: 70%;
            transition-delay: random()+s;
        }
        &--jquery {
            width: 80%;
           transition-delay: random()+s;
        }
        &--react {
            width: 50%;
            transition-delay: random()+s;
        }
        &--vue {
            width: 50%;
            transition-delay: random()+s;
        }
        &--sass {
            width: 90%;
            transition-delay: random()+s;
        }
        &--gulp {
            width: 70%;
            transition-delay: random()+s;
        }
        &--git {
            width: 75%;
            transition-delay: random()+s;
        }
        &--svn {
            width: 75%;
            transition-delay: random()+s;
        }
        &--bootstrap {
            width: 90%;
            transition-delay: random()+s;
        }
        &--no-val {
            width: 0;
        }
    }
}