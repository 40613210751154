@mixin vertical-align {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin stylized-links {
    border-bottom: 3px solid $blue;
    padding: 2px 4px;
    background-image: linear-gradient(to top, $blue 50%, white 50%);
    background-size: 100% 200%;
    background-position: top;
    transition: all 0.5s ease-in-out;

    &:hover {
        background-position: bottom;
        color: white;
    }
}