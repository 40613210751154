/*
#   _______   ______     ______   .___________. _______ .______
#  |   ____| /  __  \   /  __  \  |           ||   ____||   _  \
#  |  |__   |  |  |  | |  |  |  | `---|  |----`|  |__   |  |_)  |
#  |   __|  |  |  |  | |  |  |  |     |  |     |   __|  |      /
#  |  |     |  `--'  | |  `--'  |     |  |     |  |____ |  |\  \----.
#  |__|      \______/   \______/      |__|     |_______|| _| `._____|
#
*/

.footer {
    background: #545454;
    color: white;
    padding: 20px 0 5px 20px;

    @media (min-width: 75em) { // width: 1200px
        & {
            padding-left: 20%;
            padding-right: 20%;
        }
    }

    &__about {
        width: 310px;
        height: 100%;
        margin: 0 auto;
    }

    &__tagline {
        border-bottom: 1px solid white;
        padding: 10px 10px 20px 0;
    }

    &__contact-info {
        font-weight: 200;
        padding: 20px 0 0 0;

        &--phone, &--email {
            margin: 0 0 20px;
            display: inline-block;

            @media (min-width: 37.5em) { // width: 600px
                & {
                    font-size: 20.8px;
                    font-size: 1.2rem;
                }
            }
            @media (min-width: 56.25em) { // width: 900px
                & {
                    font-size: 24px;
                    font-size: 1.3rem;
                }
            }
            @media (min-width: 75em) { // width: 1200px
                & {
                    font-size: 27.2px;
                    font-size: 1.4rem;
                }
            }
        }

        &--phone {
            margin-right: 30px;
        }

        &--phone:before {
            background: url(../../images/phone.png) 0 0 no-repeat;
        }
        &--email:before {
            background: url(../../images/email.png) 0 0 no-repeat;
        }

        &--phone:before, &--email:before {
            content: '';
            background-size: 30px;
            width: 30px;
            height: 30px;
            display: inline-block;
            position: relative;
            padding-right: 20px;
            vertical-align: middle;
        }
    }

    &__social {
        height: 40px;
        width: 100%;
        text-align: center;
        // border-left: 1px solid white;

        @media (min-width: 37.5em) { // width: 600px
            & {
                height: 50px;
            }
        }
        @media (min-width: 56.25em) { // width: 900px
            & {
                height: 70px;
            }
        }
    }
    &__social-item {
        margin: 20px 0;
        display: inline-block;

        @media (min-width: 37.5em) { // width: 600px
            & {
                margin: 30px 0;
            }
        }
    }

    &__social-image {
        width: 30px;
        height: 30px;
        display: inline-block;
        margin: 0 20px;

        &:hover {
            filter: invert(100%);
            cursor: pointer;
        }

        @media (min-width: 37.5em) { // width: 600px
            & {
                width: 30px;
                height: 30px;
            }
        }
        @media (min-width: 56.25em) { // width: 900px
            & {
                width: 50px;
                height: 50px;
            }
        }
    }
}

.fin {
    width: 100%;
    text-align: center;
    margin: 20px 0 0;

    .heart:before {
       content: '\2665';
       color: $red;
    }
}